<template>
  <div>
    <template v-if="appName === 'FlipPay'">
      <div class="site-logo self-center" @click="handleLogoLink">
        <img :src="flipLogo" alt="Flippay" />
      </div>
    </template>
    <template v-else-if="appName === 'RelloPay'">
      <div class="site-logo self-center" @click="handleLogoLink">
        <img :src="relloLogo" alt="RelloPay" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      relloLogo: require('@/assets/images/logo/rellopay-logo.svg'),
      flipLogo: require('@/assets/images/logo/flippay-logo.svg'),
      appName: process.env.VUE_APP_NAME || "FlipPay",
    };
  },
  methods: {
    handleLogoLink() {},
  },
};
</script>
